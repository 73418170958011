import React from 'react'
import Aboutdetails from '../components/AboutDetails/Aboutdetails'
import Footer from '../components/footer/Footer'
import Header from '../components/Header/Header'

const About = () => {
  return (
    <>
  <Header/>
  



<Aboutdetails />
  <Footer/>

</>

  )
}

export default About