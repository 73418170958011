import React from 'react'
import Contactdetails from '../components/Contactdetails/Contactdetails'
import Footer from '../components/footer/Footer'
import Header from '../components/Header/Header'

const Contact = () => {
  return (
    <div>
        <Header/>
        <Contactdetails/>
        <footer>
            <div class="footer-area-two footer-area-three">
                <div class="container">
                    <div class="footer-top-two">
                        <div class="row justify-content-center">
                            <div class="col-xl-6 col-lg-8">
                                <div class="footer-content-two text-center">
                                    <div class="logo">
                                        <a href="index.html"><img src="assets/img/logo/newlogo.png" alt=""/></a>
                                    </div>
                                    <p>Agency is a full-service agency, busy designing and building beautiful digital products,
                                        brands, and experiences.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-bottom-two">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="copyright-text">
                                    <p>© 2021, Matrix. All Rights Reserved</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="footer-social-two">
                                    {/* <ul class="list-wrap">
                                        <li class="title">Follow us</li>
                                        <li><a href="#"><img src="assets/img/icon/footer_icon01.png" alt=""/></a></li>
                                        <li><a href="#"><img src="assets/img/icon/footer_icon02.png" alt=""/></a></li>
                                        <li><a href="#"><img src="assets/img/icon/footer_icon03.png" alt=""/></a></li>
                                        <li><a href="#"><img src="assets/img/icon/footer_icon04.png" alt=""/></a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <Footer/> */}
    </div>
  )
}

export default Contact