import React, { useEffect } from 'react'

const ServiceDetails = () => {

    useEffect(() => {
        window.scrollTo(0,0)
       
        
       }, [])
  return (
    <div>
         <main>


<section class="breadcrumb-area breadcrumb-area-two pt-175">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-content">
                    <p>Our Company's About Details.</p>
                    <h2 class="title">Creating Amazing/Meorable Digital Experiences</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Service Details</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="services-details-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-10">
                <div class="services-details-img text-center">
                    <img src="assets/img/images/services_details_img.png" alt=""/>
                </div>
                <div class="services-details-content text-center">
                    <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac scelerisque nibh dolores consectetuer, nulla aptent est pede. Scelerisque euismod varius mi, congue eget sed vestibulum, ornare cras sed nec.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="services-details-shape">
        <img src="assets/img/images/services_details_shape.png" alt=""/>
    </div>
</div>

{/* 
<section class="counter-area-four pt-100 pb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="counter-item-wrap-four">
                    <ul class="list-wrap">
                        <li>
                            <div class="counter-item-four">
                                <h2 class="count"><span class="odometer" data-count="15"></span>K+</h2>
                                <p>Entries in <span>Accunting</span></p>
                            </div>
                        </li>
                        <li>
                            <div class="counter-item-four">
                                <h2 class="count"><span class="odometer" data-count="120"></span>+</h2>
                                <p>International <span>Programs</span></p>
                            </div>
                        </li>
                        <li>
                            <div class="counter-item-four">
                                <h2 class="count"><span class="odometer" data-count="46"></span>+</h2>
                                <p>Countries in<span>The World</span></p>
                            </div>
                        </li>
                        <li>
                            <div class="counter-item-four">
                                <h2 class="count"><span class="odometer" data-count="17"></span>+</h2>
                                <p>Awards <span>Programs</span></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> */}

{/* <section class="company-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-9">
                <div class="section-title white-title text-center mb-65">
                    <span class="sub-title">Our Company</span>
                    <h2 class="title">We Create Creative Designs For Every Web Page</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="company-img">
                    <img src="assets/img/images/company_img01.png" alt=""/>
                    <img src="assets/img/images/company_img02.png" alt="" data-parallax='{"x" : 120 }'/>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="company-content">
                    <h2 class="title">All Your Questions Are Here</h2>
                    <div class="company-list">
                        <ul class="list-wrap">
                            <li>
                                <img src="assets/img/icon/check_icon.png" alt=""/>
                                Seo quotes to inspire your campaign
                            </li>
                            <li>
                                <img src="assets/img/icon/check_icon.png" alt=""/>
                                Much easier to double your business
                            </li>
                            <li>
                                <img src="assets/img/icon/check_icon.png" alt=""/>
                                Free page speed insights tool to find out exactly
                            </li>
                            <li>
                                <img src="assets/img/icon/check_icon.png" alt=""/>
                                Seo quotes to inspire your campaign
                            </li>
                        </ul>
                    </div>
                    <a href="about-me.html" class="btn">Discover More <span></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="company-shape-wrap">
        <img src="assets/img/images/company_shape.png" alt=""/>
    </div>
</section> */}

{/* <section class="inner-services-area-two pt-110 pb-90">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-8">
                <div class="section-title text-center mb-65">
                    <h2 class="title">We Create Creative Designs For Every Web Page</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                </div>
            </div>
        </div>
        <div class="inner-services-wrap-two">
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <div class="services-item-five">
                        <div class="services-icon-five">
                            <img src="assets/img/icon/inner_two_services_icon01.png" alt=""/>
                        </div>
                        <div class="services-content-five">
                            <h2 class="title"><a href="services-details.html">E-Commerce <span></span></a></h2>
                            <p>We provide our clients the ultimate E-commerce platform to ensure easy access, guaranteed success and long term profits for their brand.   </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <div class="services-item-five">
                        <div class="services-icon-five">
                            <img src="assets/img/icon/inner_two_services_icon02.png" alt=""/>
                        </div>
                        <div class="services-content-five">
                            <h2 class="title"><a href="services-details.html">ERP & CRM <span></span></a></h2>
                            <p>we provide our client with Fully integrated suite of tools to manage your business from small to medium sized enterprises. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <div class="services-item-five">
                        <div class="services-icon-five">
                            <img src="assets/img/icon/inner_two_services_icon03.png" alt=""/>
                        </div>
                        <div class="services-content-five">
                            <h2 class="title"><a href="services-details.html">Website<span>Development</span></a></h2>
                            <p>We provide end-to-end web development solutions and functionalities that meet your business objectives, goals and expectations. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <div class="services-item-five">
                        <div class="services-icon-five">
                            <img src="assets/img/icon/inner_two_services_icon04.png" alt=""/>
                        </div>
                        <div class="services-content-five">
                            <h2 class="title"><a href="services-details.html">Social  <span>Media</span></a></h2>
                            <p>we develop and implement innovative social media strategies for maximum business relevance and impact.  </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <div class="services-item-five">
                        <div class="services-icon-five">
                            <img src="assets/img/icon/inner_two_services_icon04.png" alt=""/>
                        </div>
                        <div class="services-content-five">
                            <h2 class="title"><a href="services-details.html"> Digital Marketing<span></span></a></h2>
                            <p>We can help you develop better interactions, build better experiences and leverage customer relationships across all digital channels, through a combination of brand planning, technology</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <div class="services-item-five">
                        <div class="services-icon-five">
                            <img src="assets/img/icon/inner_two_services_icon04.png" alt=""/>
                        </div>
                        <div class="services-content-five">
                            <h2 class="title"><a href="services-details.html">App Development <span></span></a></h2>
                            <p>Our mobile application developers have deep expertise creating apps for all the major platforms including iOS (iPhone, iPad), Android, BlackBerry and Windows Mobile. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <div class="services-item-five">
                        <div class="services-icon-five">
                            <img src="assets/img/icon/inner_two_services_icon04.png" alt=""/>
                        </div>
                        <div class="services-content-five">
                            <h2 class="title"><a href="services-details.html">UI/UX Designing <span></span></a></h2>
                            <p>A UI (User Interface) deals with the application's graphical layout, which includes buttons, screen layout, animations, transitions, micro-interactions, and so on.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-10">
                    <div class="services-item-five">
                        <div class="services-icon-five">
                            <img src="assets/img/icon/inner_two_services_icon04.png" alt=""/>
                        </div>
                        <div class="services-content-five">
                            <h2 class="title"><a href="services-details.html">SEO & Content Writing <span></span></a></h2>
                            <p>SEO writing is the process of writing content with the goal of ranking on the first page of search engines like Google.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section> */}

<section class="services-area pt-35 pb-95">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-3 col-lg-7 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".2s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon01.png" alt=""/>
                                </div>
                                <div class="services-content">
                                    <h4 class="title">E-Commerce</h4>
                                    <p>We provide our clients the ultimate E-commerce platform to ensure easy access, guaranteed success and long term profits for their brand.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".4s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon02.png" alt=""/>
                                </div>
                                <div class="services-content">
                                    <h4 class="title">ERP & CRM</h4>
                                    <p>we provide our client with Fully integrated suite of tools to manage your business from small to medium sized enterprises.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".6s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon03.png" alt=""/>
                                </div>
                                <div class="services-content">
                                    <h4 class="title">Website Development</h4>
                                    <p>We provide end-to-end web development solutions and functionalities that meet your business objectives, goals and expectations.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon04.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">Social Media</h4>
                                    <p>we develop and implement innovative social media strategies for maximum business relevance and impact.  </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon01.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">Digital Marketing</h4>
                                    <p>We can help you develop better interactions, build better experiences and leverage customer relationships across all digital channels, through a combination of brand planning, technology.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon02.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">App Development</h4>
                                    <p>Our mobile application developers have deep expertise creating apps for all the major platforms including iOS (iPhone, iPad), Android, BlackBerry and Windows Mobile.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon03.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">UI/UX Designing</h4>
                                    <p>A UI (User Interface) deals with the application's graphical layout, which includes buttons, screen layout, animations, transitions, micro-interactions, and so on.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon04.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">SEO & Content Writing</h4>
                                    <p>SEO writing is the process of writing content with the goal of ranking on the first page of search engines like Google.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </section>

{/* <section class="inner-project-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6">
                <div class="section-title text-center mb-65">
                    <h2 class="title">Case Studies</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                </div>
            </div>
        </div>
        <div class="inner-project-item-wrap">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="inner-project-item">
                        <div class="inner-project-thumb">
                            <a href="project-details.html"><img src="assets/img/project/inner_project01.jpg" alt=""/></a>
                        </div>
                        <div class="inner-project-content">
                            <h3 class="title"><a href="project-details.html">Motion Design</a></h3>
                            <p>Lorem Ipsum is simply</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="inner-project-item">
                        <div class="inner-project-thumb">
                            <a href="project-details.html"><img src="assets/img/project/inner_project02.jpg" alt=""/></a>
                        </div>
                        <div class="inner-project-content">
                            <h3 class="title"><a href="project-details.html">Motion Design</a></h3>
                            <p>Lorem Ipsum is simply</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="inner-project-item">
                        <div class="inner-project-thumb">
                            <a href="project-details.html"><img src="assets/img/project/inner_project03.jpg" alt=""/></a>
                        </div>
                        <div class="inner-project-content">
                            <h3 class="title"><a href="project-details.html">Motion Design</a></h3>
                            <p>Lorem Ipsum is simply</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-6">
                    <div class="inner-project-item">
                        <div class="inner-project-thumb">
                            <a href="project-details.html"><img src="assets/img/project/inner_project04.jpg" alt=""/></a>
                        </div>
                        <div class="inner-project-content">
                            <h3 class="title"><a href="project-details.html">Motion Design</a></h3>
                            <p>Lorem Ipsum is simply</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="inner-project-item">
                        <div class="inner-project-thumb">
                            <a href="project-details.html"><img src="assets/img/project/inner_project05.jpg" alt=""/></a>
                        </div>
                        <div class="inner-project-content">
                            <h3 class="title"><a href="project-details.html">Motion Design</a></h3>
                            <p>Lorem Ipsum is simply</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="inner-project-item">
                        <div class="inner-project-thumb">
                            <a href="project-details.html"><img src="assets/img/project/inner_project06.jpg" alt=""/></a>
                        </div>
                        <div class="inner-project-content">
                            <h3 class="title"><a href="project-details.html">Motion Design</a></h3>
                            <p>Lorem Ipsum is simply</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="inner-project-item">
                        <div class="inner-project-thumb">
                            <a href="project-details.html"><img src="assets/img/project/inner_project07.jpg" alt=""/></a>
                        </div>
                        <div class="inner-project-content">
                            <h3 class="title"><a href="project-details.html">Motion Design</a></h3>
                            <p>Lorem Ipsum is simply</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="more-btn text-center mt-45">
                <a href="about-me.html" class="btn">Load More <span></span></a>
            </div>
        </div>
    </div>
</section> */}

<section class="newsletter-area pt-110 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="section-title text-center mb-80">
                    <span class="sub-title">Get update</span>
                    <h2 class="title">Get latest updates <br/> and dealsi</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="newsletter-form">
                    <form action="#">
                        <input type="email" placeholder="Enter your email address"/>
                        <button type="submit" class="btn">Subscribe <span></span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="newsletter-shape-wrap">
        <img src="assets/img/images/newsletter_bg_shape.png" alt="" class="bg-shape"/>
        <img src="assets/img/images/newsletter_shape01.png" alt="" class="shape-one"/>
        <img src="assets/img/images/newsletter_shape02.png" alt="" class="shape-two"/>
        <img src="assets/img/images/newsletter_shape03.png" alt="" class="shape-three"/>
        <img src="assets/img/images/newsletter_shape04.png" alt="" class="shape-four"/>
        <img src="assets/img/images/newsletter_shape05.png" alt="" class="shape-five"/>
        <img src="assets/img/images/newsletter_shape06.png" alt="" class="shape-six"/>
    </div>
</section>

</main> 
    </div>
  )
}

export default ServiceDetails