import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import { useEffect } from 'react';

// const LoadScript = (src)=> {

//   useEffect(() => {
//       const tag = document.createElement('script');
//       tag.async = true;
//       tag.src = src;
//       document.body.appendChild(tag);
      
//       return () => {
//         document.body.removeChild(tag);
//       }
//       }, [src])

 

//   }

function App() {

//   LoadScript('assets/js/vendor/jquery-3.6.0.min.js')
// LoadScript('assets/js/main.js')
// LoadScript('assets/js/bootstrap.min.js')
// LoadScript('assets/js/isotope.pkgd.min.js')
// LoadScript('assets/js/imagesloaded.pkgd.min.js')
// LoadScript('assets/js/jquery.magnific-popup.min.js')
// LoadScript('assets/js/jquery.odometer.min.js') 
// LoadScript('assets/js/swiper-bundle.min.js')
// LoadScript('assets/js/jquery.appear.js')
// LoadScript('assets/js/slick.min.js')
// LoadScript('assets/js/ajax-form.js')
// LoadScript('assets/js/parallax.min.js')  
// LoadScript('assets/js/jquery.parallaxScroll.min.js')  
// LoadScript('assets/js/tween-max.js')  
// LoadScript('assets/js/wow.min.js')  
// LoadScript('assets/js/tween-max.js')  

  return (
    <div className="App">
     
       <div class="mouseCursor cursor-outer"></div>
        <div class="mouseCursor cursor-inner"><span>Drag</span></div>
        
		
        <button class="scroll-top scroll-to-target" data-target="html">
            <i class="fas fa-angle-up"></i>
        </button>
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
