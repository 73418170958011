import React from 'react'
import { Link } from 'react-router-dom'
import About from '../../pages/About'
import Contact from '../../pages/Contact'
import Home from '../../pages/Home'
import Services from '../../pages/Services'

const Footer = () => {
  return (
    <div>
            <footer>
            <div class="footer-area">
                <div class="footer-top">
                    <div class="container">
                        <div class="row " style={{display:"flex ", flexWrap:"wrap"}}>
                            <div class="col-lg-4 col-sm-6 d-flex">
                                <div class="footer-widget">
                                    <div class="logo">
                                        <a href="index.html"><img src="assets/img/logo/newlogo.png" alt="" /></a>
                                    </div>
                                    <div class="footer-social">
                                        {/* <ul class="list-wrap">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li class="active"><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                        </ul> */}
                                    </div>
                                    <div class="footer-contact">
                                        <span>For Support</span>
                                        <h2 class="title"><a href="tel:+91 98926 36666">+91 98926 36666</a></h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 d-flex">
                                <div class="footer-widget">
                                    <h4 class="fw-title">Primary Pages</h4>
                                    <div class="fw-link">
                                        <ul class="list-wrap">
                                            <li><Link to="/" path={<Home/>}>Home </Link></li>
                                            <li> <Link to="/aboutus" path={<About/>}>About</Link></li>
                                            <li><Link to="/services" path={<Services/>}>Services</Link></li>
                                            <li><Link to="/contact" path={<Contact/>}>Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-3 col-sm-6 d-flex">
                                <div class="footer-widget">
                                    <h4 class="fw-title">Pages</h4>
                                    <div class="fw-link">
                                        <ul class="list-wrap">
                                            <li><a href="about-us.html">About</a></li>
                                            <li><a href="contact.html">Pricing</a></li>
                                            <li><a href="contact.html">Contact</a></li>
                                            <li><a href="contact.html">Request for Demo</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            <div class="col-lg-4 col-sm-6 d-flex">
                                <div class="footer-widget">
                                    <h4 class="fw-title d-flex">About Store</h4>
                                    <div class="footer-about">
                                        <ul class="list-wrap">
                                            <li><img src="assets/img/icon/phone_icon.svg" alt=""/><a href="tel:+91 98926 36666">+91 98926 36666</a></li>
                                            <li><img src="assets/img/icon/mail_icon.svg" alt=""/><a href="mailto:matrixitsolution.226@gmail.com">matrixitsolution.226@gmail.com</a></li>
                                            <li><img src="assets/img/icon/loction_icon.svg" alt=""/><span>Andheri (WEST), Mumbai </span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="container">
                        <div class="row align-items-center">
                            {/* <div class="col-md-6">
                                <div class="cart-img">
                                    <img src="assets/img/images/cart_img.png" alt=""/>
                                </div>
                            </div> */}
                            <div class="col-md-12">
                                <div class="copyright-text text-end">
                                    <p>© 2021 Matrix. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        
    </div>
  )
}

export default Footer