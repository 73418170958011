import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/Header/Header'
import ServiceDetails from '../components/Servicedetails/ServiceDetails'

const Services = () => {
  return (

    <div> 
        <Header/>
        <ServiceDetails/>
        <Footer/>
    </div>
  )
}

export default Services