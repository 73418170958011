import React, { useEffect } from 'react'

const Aboutdetails = () => {
    useEffect(() => {
     window.scrollTo(0,0)
    
     
    }, [])
    
  return (
    <div>
        <main>
         
<section class="breadcrumb-area pt-175 pb-140">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-content">
                    <h2 class="title">About Us</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumb-shape-wrap">
        <img src="assets/img/images/breadcrumb_shape01.png" alt=""/>
        <img src="assets/img/images/breadcrumb_shape02.png" alt=""/>
    </div>
</section>

{/* <section class="counter-area-three">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 order-0 order-lg-2">
                <div class="counter-item-wrap-three">
                    <ul class="list-wrap">
                        <li>
                            <div class="counter-item-three">
                                <div class="icon">
                                    <img src="assets/img/icon/inner_counter_icon01.png" alt=""/>
                                </div>
                                <div class="content">
                                    <h2 class="count"><span class="odometer" data-count="210"></span>+</h2>
                                    <p>Satisfied <span>Customers</span></p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="counter-item-three">
                                <div class="icon">
                                    <img src="assets/img/icon/inner_counter_icon02.png" alt=""/>
                                </div>
                                <div class="content">
                                    <h2 class="count"><span class="odometer" data-count="15"></span>k+</h2>
                                    <p>Project <span>Finished</span></p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="counter-item-three">
                                <div class="icon">
                                    <img src="assets/img/icon/inner_counter_icon03.png" alt=""/>
                                </div>
                                <div class="content">
                                    <h2 class="count"><span class="odometer" data-count="110"></span>+</h2>
                                    <p>Our <span>Employees</span></p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="counter-item-three">
                                <div class="icon">
                                    <img src="assets/img/icon/inner_counter_icon04.png" alt=""/>
                                </div>
                                <div class="content">
                                    <h2 class="count"><span class="odometer" data-count="12"></span>+</h2>
                                    <p>International <span>Awards</span></p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="counter-content-three">
                    <div class="section-title title-style-two mb-30">
                        <h2 class="title">Content Has to Be More That Just Brilliant</h2>
                    </div>
                    <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac
                    scelerisque nibh dolores consectetuer, nulla aptent est pede. Scelerisque euismod varius mi, congue eget sed vestibulum, ornare cras sed nec.</p>
                    <img src="assets/img/images/sine.png" alt=""/>
                    <div class="content-bottom">
                        <h4 class="title-two">Davis Levin</h4>
                        <span>Director Company</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}

{/* <section class="team-area-two pt-110 pb-120">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-7">
                <div class="section-title title-style-two text-center mb-70">
                    <h2 class="title">Meet The Squad</h2>
                    <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet. Ac scelerisque nibh dolores</p>
                </div>
            </div>
        </div>
        <div class="row team-active">
            <div class="col-lg-4">
                <div class="team-item">
                    <div class="team-thumb">
                        <a href="team-details.html"><img src="assets/img/team/inner_team01.jpg" alt=""/></a>
                    </div>
                    <div class="team-content">
                        <h2 class="title"><a href="team-details.html">Alena Rosser</a></h2>
                        <span>CEO Kawasaki Inc.</span>
                        <div class="team-social">
                            <ul class="list-wrap">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="team-item">
                    <div class="team-thumb">
                        <a href="team-details.html"><img src="assets/img/team/inner_team02.jpg" alt=""/></a>
                    </div>
                    <div class="team-content">
                        <h2 class="title"><a href="team-details.html">Tiana Dokidis</a></h2>
                        <span>CEO Kawasaki Inc.</span>
                        <div class="team-social">
                            <ul class="list-wrap">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="team-item">
                    <div class="team-thumb">
                        <a href="team-details.html"><img src="assets/img/team/inner_team03.jpg" alt=""/></a>
                    </div>
                    <div class="team-content">
                        <h2 class="title"><a href="team-details.html">Ryan Vetrovs</a></h2>
                        <span>CEO Kawasaki Inc.</span>
                        <div class="team-social">
                            <ul class="list-wrap">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="team-item">
                    <div class="team-thumb">
                        <a href="team-details.html"><img src="assets/img/team/inner_team04.jpg" alt=""/></a>
                    </div>
                    <div class="team-content">
                        <h2 class="title"><a href="team-details.html">Emerson Saris</a></h2>
                        <span>CEO Kawasaki Inc.</span>
                        <div class="team-social">
                            <ul class="list-wrap">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="team-item">
                    <div class="team-thumb">
                        <a href="team-details.html"><img src="assets/img/team/inner_team05.jpg" alt=""/></a>
                    </div>
                    <div class="team-content">
                        <h2 class="title"><a href="team-details.html">Lindsey Schleifer</a></h2>
                        <span>CEO Kawasaki Inc.</span>
                        <div class="team-social">
                            <ul class="list-wrap">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}

<section class="about-area mt-5">
                <div class="container custom-container">
                    <div class="about-inner">
                        <div class="row align-items-center ">
                            <div class="col-46 order-0 order-lg-2">
                                <div class="about-img text-end">
                                    <img src="assets/img/images/imgone.png" style={{objectFit:"cover",height:"500px"}} alt="" />
                                </div>
                            </div>
                            <div class="col-54">
                                <div class="about-content ">
                                    <div class="section-title mb-25 ">
                                        <span class="sub-title ">About Company</span>
                                        <h2 class="title ">Why Choose Us</h2>
                                    <p class="mt-5">While some promotional products companies are only in the business of selling products, we understand that you often need an uncommon approach to common goods. Maybe it’s a unique theme, special packaging or a design enhancement to make a common product a vehicle for marketing or a gift of goodwill. This is what sets us apart. Our campaigns effectively promote your company at events and trade shows, recognize your employees, show your customers how much you appreciate their business and much, much more. We are here to help you get your full RETURN ON INVESTMENT (R.O.I.) and more! It has been proven time and time again that marketing and promoting your business, group or organization increases community awareness and overall sales growth! Want to measure your ROI? We can make that happen too!</p>
                                    {/* <div class="about-content-bottom">
                                        <span>Think Creative Agency Are a Full Service Design</span>
                                    </div> */}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about-area mt-5">
                <div class="container custom-container">
                    <div class="about-inner">
                        <div class="row align-items-center ">
                            <div class="col-46 order-0 order-lg-2">
                                <div class="about-img text-end">
                                    <img src="assets/img/images/imagetwo.jpg" style={{objectFit:"cover",height:"500px"}} alt="" />
                                </div>
                            </div>
                            <div class="col-54">
                                <div class="about-content ">
                                    <div class="section-title mb-25 ">
                                        <span class="sub-title ">About Company</span>
                                        <h2 class="title ">What We Do</h2>
                                    <p class="mt-5">Web Design and Development Company is to create innovative products and deliver unique services which process quality and customer satisfaction to add value to your business. Our dedicated teams especially focus on the needs and requirements of the clients to build strong and long-term relations. With the aim to meet the clients demands, our teamwork to bring successful growth for various businesses</p>
                                    {/* <div class="about-content-bottom">
                                        <span>Think Creative Agency Are a Full Service Design</span>
                                        
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about-area mt-5 mb-5">
                <div class="container custom-container">
                    <div class="about-inner">
                        <div class="row align-items-center ">
                            <div class="col-46 order-0 order-lg-2">
                                <div class="about-img text-end">
                                    <img src="assets/img/images/imagethree.jpeg" style={{objectFit:"cover",height:"500px"}} alt="" />
                                </div>
                            </div>
                            <div class="col-54">
                                <div class="about-content  ">
                                    <div class="section-title mb-25  ">
                                        <span class="sub-title ">About Company</span>
                                        <h2 class="title ">Who We Are?</h2>
                                    <p class="mt-3">We at Matrix are an experienced and passionate team of professionals that live and breathe brand. All our services are fully integrated to exceed your business requirements.We are pioneer in presenting genuine & inventive web design, web development, and graphic design solutions in slicing, adaptation, assimilation and customization services.From conceptualization to full website development implementation we have serviced our clients in wide range of industries whether it’s a start up or a big company. From PSD to HTML, PSD to Magento, WordPress,and Shopify our web development services come with unmatched customer support. We embrace latest technologies to meet your business challenges precisely.</p>
                                    {/* <div class="about-content-bottom">
                                        <span>Think Creative Agency Are a Full Service Design</span>
                                    </div> */}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

<section class="consultation-area consultation-area-two pt-120 pb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="consultation-content">
                    <div class="section-title mb-25">
                        <span class="sub-title">Contact Us</span>
                        <h2 class="title"> Best Digital Marketing Agency</h2>
                    </div>
                    <p>Vector art is a multi-national Graphics and digital imaging services company. We work with global leaders as well as growing companies to help achieve significant value addition and cost reduction to their graphic design, image production and management operations.</p>
                    <div class="consultation-list ">
                        {/* <ul class="list-wrap li_wraping">
                            <li>
                                <h6 class="title">12X</h6>
                                <p>Faster Order <span></span></p>
                            </li>
                            <li>
                                <h6 class="title">99%</h6>
                                <p>Processing <span>Accuracy</span></p>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="consultation-form-wrap">
                    <h4 class="title">Free Consultation</h4>
                    <form action="#">
                        <div class="form-grp">
                            <input type="text" placeholder="Name"/>
                        </div>
                        <div class="form-grp">
                            <input type="email" placeholder="Email Address"/>
                        </div>
                        <div class="form-grp">
                            <input type="text" placeholder="Phone Number"/>
                        </div>
                        <div class="form-grp">
                            <select id="shortBy" name="select" class="form-select" aria-label="Default select example">
                                <option value="">Subject</option>
                                <option>Subject One</option>
                                <option>Subject Two</option>
                                <option>Subject Three</option>
                                <option>Subject Four</option>
                            </select>
                        </div>
                        <button class="btn" type="submit">Consultation</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="consultation-shape-wrap">
        <img src="assets/img/images/consultation_shape01.png" alt="" class="shape-one ribbonRotate"/>
        <img src="assets/img/images/consultation_shape02.png" alt="" class="shape-two float-bob-x"/>
    </div>
</section>

{/* <section class="inner-services-area pt-110 pb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="section-title title-style-two text-center mb-60">
                    <span class="sub-title">Graphic Service</span>
                    <h2 class="title">Creative & Branding Design</h2>
                </div>
            </div>
        </div>
        <div class="inner-services-item-wrap">
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div class="services-item-four">
                        <div class="services-icon-four">
                            <img src="assets/img/icon/inner_services_icon01.png" alt=""/>
                        </div>
                        <div class="services-content-four">
                            <h2 class="title"><a href="services-details.html">Advertising</a></h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div class="services-item-four">
                        <div class="services-icon-four">
                            <img src="assets/img/icon/inner_services_icon02.png" alt=""/>
                        </div>
                        <div class="services-content-four">
                            <h2 class="title"><a href="services-details.html">Development</a></h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div class="services-item-four">
                        <div class="services-icon-four">
                            <img src="assets/img/icon/inner_services_icon03.png" alt=""/>
                        </div>
                        <div class="services-content-four">
                            <h2 class="title"><a href="services-details.html">Branding</a></h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div class="services-item-four">
                        <div class="services-icon-four">
                            <img src="assets/img/icon/inner_services_icon04.png" alt=""/>
                        </div>
                        <div class="services-content-four">
                            <h2 class="title"><a href="services-details.html">Product Design</a></h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div class="services-item-four">
                        <div class="services-icon-four">
                            <img src="assets/img/icon/inner_services_icon05.png" alt=""/>
                        </div>
                        <div class="services-content-four">
                            <h2 class="title"><a href="services-details.html">Software</a></h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div class="services-item-four">
                        <div class="services-icon-four">
                            <img src="assets/img/icon/inner_services_icon06.png" alt=""/>
                        </div>
                        <div class="services-content-four">
                            <h2 class="title"><a href="services-details.html">Marketing</a></h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div class="services-item-four">
                        <div class="services-icon-four">
                            <img src="assets/img/icon/inner_services_icon07.png" alt=""/>
                        </div>
                        <div class="services-content-four">
                            <h2 class="title"><a href="services-details.html">Cinematography</a></h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div class="services-item-four">
                        <div class="services-icon-four">
                            <img src="assets/img/icon/inner_services_icon08.png" alt=""/>
                        </div>
                        <div class="services-content-four">
                            <h2 class="title"><a href="services-details.html">Strategy Services</a></h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}



{/* <section class="success-area pb-120">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 order-0 order-lg-2">
                <div class="success-img">
                    <img src="assets/img/images/success_img.png" alt=""/>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="success-content">
                    <div class="section-title title-style-two mb-30">
                        <span class="sub-title">Company Success</span>
                        <h2 class="title">A Company’s Success is An Ongoing Stream of Happiness</h2>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                    <div class="success-list">
                        <ul class="list-wrap">
                            <li>
                                <div class="content">
                                    <img src="assets/img/icon/success_icon01.png" alt="" />
                                    <span>App Design 85%</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 85%;" aria-valuenow="85" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </li>
                            <li>
                                <div class="content">
                                    <img src="assets/img/icon/success_icon02.png" alt=""/>
                                    <span>Website Design 75%</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="75" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </li>
                            <li>
                                <div class="content">
                                    <img src="assets/img/icon/success_icon03.png" alt=""/>
                                    <span>Product Design 95%</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 95%;" aria-valuenow="95" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </li>
                            <li>
                                <div class="content">
                                    <img src="assets/img/icon/success_icon04.png" alt=""/>
                                    <span>Development 99%</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 99%;" aria-valuenow="99" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="success-shape-wrap">
        <img src="assets/img/images/success_shape01.png" alt=""/>
        <img src="assets/img/images/success_shape02.png" alt=""/>
    </div>
</section> */}

{/* <section class="history-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8">
                <div class="section-title white-title text-center mb-45">
                    <span class="sub-title">Our Company</span>
                    <h2 class="title">Company History</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                </div>
            </div>
        </div>
        <div class="history-inner">
            <div class="history-img">
                <img src="assets/img/images/history_img.jpg" alt=""/>
            </div>
            <div class="row g-0 justify-content-end">
                <div class="col-lg-6">
                    <div class="history-content">
                        <h2 class="title">The Intention was to Establish The Company</h2>
                        <p>Lorem ipsum dolor sit amet, sed nulla ante amet, elementum tincidunt arcu sed laoreet, natoque ac eget imperdiet.
                            Ac scelerisque nibh dolores consectetuer, nulla aptent est pede. Scelerisque euismod varius mi,</p>
                        <ul class="list-wrap">
                            <li><i class="far fa-check"></i>Everyone can design at low cost</li>
                            <li><i class="far fa-check"></i>Designing in a different way</li>
                            <li><i class="far fa-check"></i>Our designs sill be world wide best designs</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="history-roadmap-wrap">
            <div class="roadmap-line-shape">
                <span class="dot-one pulse-one"></span>
                <span class="dot-two pulse-two"></span>
            </div>
            <ul class="list-wrap">
                <li class="wow fadeInDown" data-wow-delay=".2s" data-wow-duration="1.5s">
                    <span class="dot pulse-three"></span>
                    <div class="content">
                        <h5 class="title">2020</h5>
                        <p>January 14 th</p>
                    </div>
                </li>
                <li class="wow fadeInUp" data-wow-delay=".2s" data-wow-duration="1.5s">
                    <span class="dot pulse-four"></span>
                    <div class="content">
                        <h5 class="title">2021</h5>
                        <p>January 14 th</p>
                    </div>
                </li>
                <li class="wow fadeInDown" data-wow-delay=".2s" data-wow-duration="1.5s">
                    <span class="dot pulse-five"></span>
                    <div class="content">
                        <h5 class="title">2022</h5>
                        <p>January 14 th</p>
                    </div>
                </li>
                <li class="wow fadeInUp" data-wow-delay=".2s" data-wow-duration="1.5s">
                    <span class="dot pulse-six"></span>
                    <div class="content">
                        <h5 class="title">2023</h5>
                        <p>January 14 th</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="history-shape-wrap">
        <img src="assets/img/images/history_shape01.png" alt=""/>
        <img src="assets/img/images/history_shape02.png" alt=""/>
    </div>
</section> */}

{/* <section class="testimonial-area-three testimonial-area-four">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-8">
                <div class="section-title title-style-two mb-45">
                    <h2 class="title">What Our Client’s Say</h2>
                </div>
            </div>
            <div class="col-md-4">
                <div class="testimonial-nav">
                    <button class="swiper-button-prev"></button>
                    <button class="swiper-button-next"></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="swiper-container testimonial-active-three">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="testimonial-item-three">
                                <div class="testimonial-thumb-three">
                                    <img src="assets/img/images/testimonial_avatar01.png" alt=""/>
                                </div>
                                <div class="testimonial-content-three">
                                    <h4 class="title">James Botosh</h4>
                                    <span>Product Designer</span>
                                    <p>“We seek to get involved early in the design phase so that we can manage the project more efficiently, provide effective building solutions”</p>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="testimonial-item-three">
                                <div class="testimonial-thumb-three">
                                    <img src="assets/img/images/testimonial_avatar02.png" alt=""/>
                                </div>
                                <div class="testimonial-content-three">
                                    <h4 class="title">James Botosh</h4>
                                    <span>Product Designer</span>
                                    <p>“We seek to get involved early in the design phase so that we can manage the project more efficiently, provide effective building solutions”</p>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="testimonial-item-three">
                                <div class="testimonial-thumb-three">
                                    <img src="assets/img/images/testimonial_avatar03.png" alt=""/>
                                </div>
                                <div class="testimonial-content-three">
                                    <h4 class="title">James Botosh</h4>
                                    <span>Product Designer</span>
                                    <p>“We seek to get involved early in the design phase so that we can manage the project more efficiently, provide effective building solutions”</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}

{/* <div class="brand-area pb-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <span class="title">We are Already Build Solution for...</span>
            </div>
        </div>
        <div class="row brand-active">
            <div class="col-12">
                <div class="brand-item-two">
                    <img src="assets/img/brand/h3_brand_img01.png" alt=""/>
                </div>
            </div>
            <div class="col-12">
                <div class="brand-item-two">
                    <img src="assets/img/brand/h3_brand_img02.png" alt=""/>
                </div>
            </div>
            <div class="col-12">
                <div class="brand-item-two">
                    <img src="assets/img/brand/h3_brand_img03.png" alt=""/>
                </div>
            </div>
            <div class="col-12">
                <div class="brand-item-two">
                    <img src="assets/img/brand/h3_brand_img04.png" alt=""/>
                </div>
            </div>
            <div class="col-12">
                <div class="brand-item-two">
                    <img src="assets/img/brand/h3_brand_img05.png" alt=""/>
                </div>
            </div>
            <div class="col-12">
                <div class="brand-item-two">
                    <img src="assets/img/brand/h3_brand_img06.png" alt=""/>
                </div>
            </div>
        </div>
    </div>
</div> */}

</main>
    </div>
  )
}

export default Aboutdetails