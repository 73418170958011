import React, { useEffect } from 'react'

const Contactdetails = () => {
    useEffect(() => {
        window.scrollTo(0,0)
       
        
       }, [])
  return (
    <div>
         <main>


<section class="breadcrumb-area breadcrumb-area-four pt-175 pb-160">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-content">
                    <h2 class="title">Contact Us</h2>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Contact</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="breadcrumb-shape-wrap-three">
        <img src="assets/img/images/breadcrumb_shape04.png" alt="" class="wow zoomIn" data-wow-delay=".2s"/>
        <img src="assets/img/images/breadcrumb_shape05.png" alt="" class="wow zoomIn" data-wow-delay=".2s"/>
        <img src="assets/img/images/breadcrumb_shape06.png" alt="" class="wow zoomIn" data-wow-delay=".2s"/>
    </div>
</section>

<section class="inner-contact-area">
    <div class="container">
        <div class="inner-contact-wrap">
            <div class="row">
                <div class="col-xl-9 col-lg-10">
                    <div class="section-title title-style-two mb-50">
                        <p>Our Company's Contact Details.</p>
                        <h2 class="title">You are in the right place.</h2>
                    </div>
                    <div class="inner-contact-form-wrap">
                        <form action="#">
                            <div class="form-grp">
                                <label for="name"><i class="fas fa-user"></i></label>
                                <input type="text" id="name" placeholder="Name"/>
                            </div>
                            <div class="form-grp">
                                <label for="phone"><i class="fas fa-phone"></i></label>
                                <input type="text" id="phone" placeholder="Phone"/>
                            </div>
                            <div class="form-grp">
                                <label for="email"><i class="fas fa-envelope"></i></label>
                                <input type="email" id="email" placeholder="Email Address"/>
                            </div>
                            <div class="form-grp">
                                <label for="subject"><i class="fas fa-book-alt"></i></label>
                                <input type="text" id="subject" placeholder="Subject"/>
                            </div>
                            <div class="form-grp">
                                <label for="comment"><i class="fas fa-user-edit"></i></label>
                                <textarea name="comment" id="comment" placeholder="How can we help you? Feel free to get in touch!"></textarea>
                            </div>
                            <button type="submit" class="btn">Send Message <span></span></button>
                        </form>
                    </div>
                    <div id="contact-map">
                        {/* <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96811.54759587669!2d-74.01263924803828!3d40.6880494567041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25bae694479a3%3A0xb9949385da52e69e!2sBarclays%20Center!5e0!3m2!1sen!2sbd!4v1636195194646!5m2!1sen!2sbd"
                            allowfullscreen loading="lazy"></iframe> */}
                    </div>
                    <div class="inner-contact-info">
                        <ul class="list-wrap">
                            <li>
                                <div class="contact-info-item">
                                    <div class="icon">
                                        <img src="assets/img/icon/loction_icon03.png" alt=""/>
                                    </div>
                                    <div class="content">
                                        <h6 class="title">Address</h6>
                                        <p>Andheri West</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="contact-info-item">
                                    <div class="icon">
                                        <img src="assets/img/icon/mail_icon03.png" alt=""/>
                                    </div>
                                    <div class="content">
                                        <h6 class="title">Email</h6>
                                        <a href="mailto:your@email.com">your@email.com</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="contact-info-item">
                                    <div class="icon">
                                        <img src="assets/img/icon/phone_icon03.png" alt=""/>
                                    </div>
                                    <div class="content">
                                        <h6 class="title">Phone</h6>
                                        <a href="tel:+91 98926 36666">+91 98926 36666</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


</main>
    </div>
  )
}

export default Contactdetails