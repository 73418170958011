import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import Header from '../components/Header/Header';
import About from './About';
import Contact from './Contact';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0,0)
       
        
       }, [])
  return (
    <>
       
        {/* <div class="mouseCursor cursor-outer"></div>
        <div class="mouseCursor cursor-inner"><span>Drag</span></div>
        
		
        <button class="scroll-top scroll-to-target" data-target="html">
            <i class="fas fa-angle-up"></i>
        </button> */}
{/*        
        <header>
            <div id="sticky-header" class="menu-area transparent-header">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="mobile-nav-toggler"><i class="fas fa-bars"></i></div>
                            <div class="menu-wrap">
                                <nav class="menu-nav">
                                    <div class="logo">
                                        <a href="index.html"><img src="assets/img/logo/logo.png" alt="Logo"/></a>
                                    </div>
                                    <div class="navbar-wrap main-menu d-none d-lg-flex">
                                        <ul class="navigation">
                                            <li class="active menu-item-has-children"><a href="#">Home</a>
                                                <ul class="sub-menu">
                                                    <li class="active"><a href="index.html">Creative Agency</a></li>
                                                    <li><a href="index-2.html">Personal Portfolio</a></li>
                                                    <li><a href="index-3.html">Digital Agency</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="about-us.html">About Us</a></li>
                                            <li class="menu-item-has-children"><a href="#">pages</a>
                                                <ul class="sub-menu">
                                                    <li><a href="about-me.html">About Me</a></li>
                                                    <li><a href="team.html">Team Page</a></li>
                                                    <li><a href="team-details.html">Team Details</a></li>
                                                    <li><a href="project-details.html">Portfolio Details</a></li>
                                                    <li><a href="services-details.html">Services Details</a></li>
                                                    <li><a href="contact.html">Contact Us</a></li>
                                                </ul>
                                            </li>
                                            <li class="menu-item-has-children"><a href="#">News</a>
                                                <ul class="sub-menu">
                                                    <li><a href="blog.html">Our Blog</a></li>
                                                    <li><a href="blog-details.html">Blog Details</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="header-action">
                                        <ul class="list-wrap">
                                            <li class="header-btn"><a href="#!" class="btn">Contact <span></span></a></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                         
                            <div class="mobile-menu">
                                <nav class="menu-box">
                                    <div class="close-btn"><i class="fas fa-times"></i></div>
                                    <div class="nav-logo">
                                        <a href="index.html"><img src="assets/img/logo/logo.png" alt="Logo"/></a>
                                    </div>
                                    <div class="menu-outer">
                                       
                                    </div>
                                    <div class="social-links">
                                        <ul class="clearfix list-wrap">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div class="menu-backdrop"></div>
                          

                        </div>
                    </div>
                </div>

              
                <div class="header-contact-wrap">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="left-side-content">
                                    <h3 class="title">Contact</h3>
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-5 col-md-6">
                                            <div class="contact-info-list">
                                                <ul class="list-wrap">
                                                    <li>
                                                        <div class="icon">
                                                            <svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M1.5 6.5C1.5 4.89295 1.95538 3.86848 2.63534 3.2371C3.32535 2.59637 4.36497 2.25 5.75 2.25H15.75C17.135 2.25 18.1746 2.59637 18.8647 3.2371C19.5446 3.86848 20 4.89295 20 6.5V13.5C20 15.107 19.5446 16.1315 18.8647 16.7629C18.1746 17.4036 17.135 17.75 15.75 17.75H5.75C4.36497 17.75 3.32535 17.4036 2.63534 16.7629C1.95538 16.1315 1.5 15.107 1.5 13.5V6.5ZM5.75 0.75C4.13503 0.75 2.67465 1.15363 1.61466 2.1379C0.544615 3.13152 0 4.60705 0 6.5V13.5C0 15.393 0.544615 16.8685 1.61466 17.8621C2.67465 18.8464 4.13503 19.25 5.75 19.25H15.75C17.365 19.25 18.8254 18.8464 19.8853 17.8621C20.9554 16.8685 21.5 15.393 21.5 13.5V6.5C21.5 4.60705 20.9554 3.13152 19.8853 2.1379C18.8254 1.15363 17.365 0.75 15.75 0.75H5.75ZM16.2181 7.58602C16.5417 7.32751 16.5945 6.85558 16.336 6.53194C16.0775 6.20829 15.6056 6.15548 15.2819 6.41398L12.1529 8.91324L12.1525 8.91352C11.3961 9.51536 10.0946 9.51549 9.33799 8.91393L6.21898 6.41471C5.89574 6.1557 5.42373 6.20778 5.16471 6.53102C4.9057 6.85426 4.95778 7.32627 5.28102 7.58529L8.40101 10.0853L8.40286 10.0868C9.70626 11.1244 11.7837 11.1244 13.0871 10.0868L13.0881 10.086L16.2181 7.58602Z" />
                                                            </svg>
                                                        </div>
                                                        <div class="content">
                                                            <a href="mailto:company@gmail.com">company@gmail.com</a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="icon">
                                                            <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M2.48199 7.65591C3.37522 3.73032 6.71708 1.74769 10.1338 1.75C13.5508 1.75231 16.8905 3.73971 17.7791 7.66556L17.7791 7.6656C18.8362 12.3354 15.943 16.3756 13.2111 18.999L13.2097 19.0004C11.4913 20.659 8.77127 20.661 7.04076 18.9997C4.31866 16.3762 1.42493 12.3261 2.48199 7.65591ZM10.1348 0.250002C14.1154 0.252693 18.1706 2.60029 19.2421 7.33441C20.4848 12.8239 17.0589 17.3834 14.251 20.08C11.9497 22.3008 8.31046 22.2989 6.00106 20.0809L6.00012 20.08C3.20236 17.3836 -0.22362 12.8139 1.01908 7.32441L1.01926 7.32364C2.09618 2.58953 6.1542 0.247312 10.1348 0.250002ZM7.76166 9.30994C7.76166 8.00103 8.82274 6.93994 10.1317 6.93994C11.4406 6.93994 12.5017 8.00103 12.5017 9.30994C12.5017 10.6189 11.4406 11.6799 10.1317 11.6799C8.82274 11.6799 7.76166 10.6189 7.76166 9.30994ZM10.1317 5.43994C7.99432 5.43994 6.26166 7.1726 6.26166 9.30994C6.26166 11.4473 7.99432 13.1799 10.1317 13.1799C12.269 13.1799 14.0017 11.4473 14.0017 9.30994C14.0017 7.1726 12.269 5.43994 10.1317 5.43994Z" />
                                                            </svg>
                                                        </div>
                                                        <div class="content">
                                                            <span>Ranelagh Place, Liverpool, L3 5UL, England</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="icon">
                                                            <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M1.5 11C1.5 5.89421 5.64421 1.75 10.75 1.75C15.8558 1.75 20 5.89421 20 11C20 16.1058 15.8558 20.25 10.75 20.25C5.64421 20.25 1.5 16.1058 1.5 11ZM10.75 0.25C4.81579 0.25 0 5.06579 0 11C0 16.9342 4.81579 21.75 10.75 21.75C16.6842 21.75 21.5 16.9342 21.5 11C21.5 5.06579 16.6842 0.25 10.75 0.25ZM11.1289 6.50977C11.1289 6.09555 10.7931 5.75977 10.3789 5.75977C9.96469 5.75977 9.62891 6.09555 9.62891 6.50977V10.6098C9.62891 11.0862 9.79027 11.5637 10.0149 11.9576C10.2393 12.3511 10.5673 12.7322 10.9755 12.9744L10.9766 12.975L14.0746 14.8238C14.4303 15.0361 14.8907 14.9198 15.1029 14.5641C15.3152 14.2084 15.1989 13.748 14.8432 13.5357L11.7433 11.6857L11.7413 11.6845C11.6099 11.6067 11.4483 11.4431 11.3179 11.2145C11.1875 10.9859 11.1289 10.7633 11.1289 10.6098V6.50977Z" />
                                                            </svg>
                                                        </div>
                                                        <div class="content">
                                                            <span>7 Days a week <br/> from 10-00 am to 6-00 pm</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div class="lats-chat">
                                                    <a href="contact.html">Lat’s Chat</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-8 col-lg-7 col-md-6">
                                            <div class="our-best-project">
                                                <div class="content">
                                                    <h4 class="title">One of Our <span>Best Ongoing</span> Projects</h4>
                                                    <a href="project-details.html" class="btn">Details <span></span></a>
                                                </div>
                                                <div class="thumb">
                                                    <img src="assets/img/images/our_project_img.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="customer-support-wrap">
                                    <h4 class="title">Customer Support</h4>
                                    <div class="customer-support-content">
                                        <div class="content-top">
                                            <img src="assets/img/images/support_img.png" alt=""/>
                                            <h4 class="title">Need Help Choosing a Plan?</h4>
                                        </div>
                                        <p>We offer solutions for businesses of all sizes. For questions about our plans and products,,
                                            contact our team of experts. <a href="contact.html">Get in touch</a></p>
                                    </div>
                                    <div class="content-bottom">
                                        <a href="contact.html">Support</a>
                                        <a href="contact.html">Documentation</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body-contact-overlay"></div>
                
            </div>
        </header> */}
       <Header />



       
       

           <div>
            <section class="banner-area banner-bg" >
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-img wow fadeInLeft" data-wow-delay=".4s">
                                <img src="assets/img/banner/banner_img.png" alt=""/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="banner-content">
                                <span class="sub-title wow fadeInUp" data-wow-delay=".2s">Amazing <strong>Starts</strong> Here</span>
                                <h2 class="title wow fadeInUp" data-wow-delay=".4s">DIGITAL AGENCY</h2>
                                <p>We turn ideas into extraordinary digital products & experiences.</p>
                                
                              <Link to="/aboutus" path={<About/>} class="btn wow fadeInUp" data-wow-delay=".6s"> About Us <span></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-shape-wrap">
                    <img src="assets/img/banner/banner_shape01.png" alt=""/>
                    <img src="assets/img/banner/banner_shape02.png" alt="" class="animationFramesOne"/>
                    <img src="assets/img/banner/banner_shape03.png" alt="" class="contactSwimmer"/>
                    <img src="assets/img/banner/banner_shape04.png" alt="" class="rotateme"/>
                    <img src="assets/img/banner/banner_shape05.png" alt="" class="animation1"/>
                    <img src="assets/img/banner/banner_shape06.png" alt="" class="ribbonRotate"/>
                    <img src="assets/img/banner/banner_shape07.png" alt="" class="float-bob-x"/>
                </div>
            </section>
            </div>
          
            <section class="services-area pt-35 pb-95">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-3 col-lg-7 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".2s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon01.png" alt=""/>
                                </div>
                                <div class="services-content">
                                    <h4 class="title">E-Commerce</h4>
                                    <p>We provide our clients the ultimate E-commerce platform to ensure easy access, guaranteed success and long term profits for their brand.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".4s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon02.png" alt=""/>
                                </div>
                                <div class="services-content">
                                    <h4 class="title">ERP & CRM</h4>
                                    <p>we provide our client with Fully integrated suite of tools to manage your business from small to medium sized enterprises.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".6s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon03.png" alt=""/>
                                </div>
                                <div class="services-content">
                                    <h4 class="title">Website Development</h4>
                                    <p>We provide end-to-end web development solutions and functionalities that meet your business objectives, goals and expectations.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon04.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">Social Media</h4>
                                    <p>we develop and implement innovative social media strategies for maximum business relevance and impact.  </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon01.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">Digital Marketing</h4>
                                    <p>We can help you develop better interactions, build better experiences and leverage customer relationships across all digital channels, through a combination of brand planning, technology.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon02.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">App Development</h4>
                                    <p>Our mobile application developers have deep expertise creating apps for all the major platforms including iOS (iPhone, iPad), Android, BlackBerry and Windows Mobile.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon03.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">UI/UX Designing</h4>
                                    <p>A UI (User Interface) deals with the application's graphical layout, which includes buttons, screen layout, animations, transitions, micro-interactions, and so on.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-10">
                            <div class="services-item wow fadeInUp" data-wow-delay=".8s">
                                <div class="services-icon">
                                    <img src="assets/img/icon/services_icon04.png" alt="" />
                                </div>
                                <div class="services-content">
                                    <h4 class="title">SEO & Content Writing</h4>
                                    <p>SEO writing is the process of writing content with the goal of ranking on the first page of search engines like Google.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </section>
           
            {/* <section class="about-area">
                <div class="container custom-container">
                    <div class="about-inner">
                        <div class="row align-items-center ">
                            <div class="col-46 order-0 order-lg-2">
                                <div class="about-img text-end">
                                    <img src="assets/img/images/imgone.png" style={{objectFit:"cover",height:"500px"}} alt="" />
                                </div>
                            </div>
                            <div class="col-54">
                                <div class="about-content ">
                                    <div class="section-title mb-25 ">
                                        <span class="sub-title ">About Company</span>
                                        <h2 class="title ">Why Choose Us</h2>
                                    </div>
                                    <p class="">While some promotional products companies are only in the business of selling products, we understand that you often need an uncommon approach to common goods. Maybe it’s a unique theme, special packaging or a design enhancement to make a common product a vehicle for marketing or a gift of goodwill. This is what sets us apart. Our campaigns effectively promote your company at events and trade shows, recognize your employees, show your customers how much you appreciate their business and much, much more. We are here to help you get your full RETURN ON INVESTMENT (R.O.I.) and more! It has been proven time and time again that marketing and promoting your business, group or organization increases community awareness and overall sales growth! Want to measure your ROI? We can make that happen too!</p> */}
                                    {/* <ul class="list-wrap">
                                        <li>
                                            <div class="icon">
                                                <img src="assets/img/icon/about_icon01.png" alt="" />
                                            </div>
                                            <div class="content">
                                                <h4 class="title">Concept Creation</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src="assets/img/icon/about_icon02.png" alt="" />
                                            </div>
                                            <div class="content">
                                                <h4 class="title">Sketch Drawing</h4>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="about-content-bottom">
                                        <span>Think Creative Agency Are a Full Service Design</span>
                                        <div class="read-more-btn">
                                            <a href="about-us.html" class="btn">Read More <span></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section class="about-area mt-5">
                <div class="container custom-container">
                    <div class="about-inner">
                        <div class="row align-items-center ">
                            <div class="col-46 order-0 order-lg-2">
                                <div class="about-img text-end">
                                    <img src="assets/img/images/imgone.png" style={{objectFit:"cover",height:"500px"}} alt="" />
                                </div>
                            </div>
                            <div class="col-54">
                                <div class="about-content ">
                                    <div class="section-title mb-25 ">
                                        <span class="sub-title ">About Company</span>
                                        <h2 class="title ">Why Choose Us</h2>
                                    </div>
                                    <p class="">While some promotional products companies are only in the business of selling products, we understand that you often need an uncommon approach to common goods. Maybe it’s a unique theme, special packaging or a design enhancement to make a common product a vehicle for marketing or a gift of goodwill. This is what sets us apart. Our campaigns effectively promote your company at events and trade shows, recognize your employees, show your customers how much you appreciate their business and much, much more. We are here to help you get your full RETURN ON INVESTMENT (R.O.I.) and more! It has been proven time and time again that marketing and promoting your business, group or organization increases community awareness and overall sales growth! Want to measure your ROI? We can make that happen too!</p>
                                    <div class="about-content-bottom">
                                        <span>Think Creative Agency Are a Full Service Design</span>
                                        <div class="read-more-btn">
                                            <a href="about-us.html" class="btn">Read More <span></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
          
            {/* <section class="team-area pt-130 pb-130">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="team-img-wrap">
                                <img src="assets/img/team/team_img_shape.png" alt="" class="img-shape"/>
                                <img src="assets/img/team/team_img01.png" alt="" class="img-one"/>
                                <img src="assets/img/team/team_img02.png" alt="" class="img-two"/>
                                <img src="assets/img/team/team_img03.png" alt="" class="img-three"/>
                                <img src="assets/img/team/team_img04.png" alt="" class="img-four"/>
                                <img src="assets/img/team/team_img05.png" alt="" class="img-five"/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="team-content">
                                <div class="section-title mb-25">
                                    <span class="sub-title">Our Team</span>
                                    <h2 class="title">An Essential Aspect of Creativity is Not Being Afraid to Fail</h2>
                                </div>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                                <a href="team.html" class="btn">Meet Our Team <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="team-shape-wrap">
                    <img src="assets/img/images/team_shape01.png" alt="" class="ribbonRotate"/>
                    <img src="assets/img/images/team_shape02.png" alt="" class="float-bob-x"/>
                </div>
            </section> */}
           
            {/* <section class="project-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-4">
                            <div class="project-content">
                                <div class="section-title white-title mb-30">
                                    <span class="sub-title">Our Complete Craft</span>
                                    <h2 class="title">Some of Our Work in Craft Digital Agency</h2>
                                </div>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
                                <div class="content-bottom">
                                    <a href="about-me.html" class="btn">View All Project <span></span></a>
                                    <div class="project-nav">
                                        <button class="swiper-button-prev"></button>
                                        <button class="swiper-button-next"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-8">
                            <div class="project-item-wrap">
                                <div class="swiper-container project-active">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide">
                                            <div class="project-item">
                                                <a href="project-details.html"><img src="assets/img/project/project_img01.jpg" alt=""/></a>
                                            </div>
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="project-item">
                                                <a href="project-details.html"><img src="assets/img/project/project_img02.jpg" alt=""/></a>
                                            </div>
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="project-item">
                                                <a href="project-details.html"><img src="assets/img/project/project_img03.jpg" alt=""/></a>
                                            </div>
                                        </div>/
                                        <div class="swiper-slide">
                                            <div class="project-item">
                                                <a href="project-details.html" class="popup-image"><img src="assets/img/project/project_img02.jpg" alt=""/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="project-shape-wrap">
                    <img src="assets/img/project/project_shape01.png" alt="" class="shape-one ribbonRotate"/>
                    <img src="assets/img/project/project_shape02.png" alt="" class="shape-two ribbonRotate"/>
                </div>
            </section> */}
{/*            
            <section class="testimonial-area pt-110 pb-120">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6">
                            <div class="section-title text-center mb-60">
                                <span class="sub-title">Testimonials</span>
                                <h2 class="title">Shat Our Customer Say About Us</h2>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-item-wrap">
                        <div class="row testimonial-active">
                            <div class="col-lg-3">
                                <div class="testimonial-item">
                                    <div class="testimonial-content">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “</p>
                                    </div>
                                    <div class="testimonial-info">
                                        <div class="thumb">
                                            <img src="assets/img/images/testimonial_avatar01.png" alt=""/>
                                        </div>
                                        <div class="content">
                                            <h4 class="title">Davis Levin</h4>
                                            <p>CEO Kawasaki Inc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="testimonial-item">
                                    <div class="testimonial-content">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “</p>
                                    </div>
                                    <div class="testimonial-info">
                                        <div class="thumb">
                                            <img src="assets/img/images/testimonial_avatar02.png" alt="" />
                                        </div>
                                        <div class="content">
                                            <h4 class="title">Davis Levin</h4>
                                            <p>CEO Kawasaki Inc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="testimonial-item">
                                    <div class="testimonial-content">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “</p>
                                    </div>
                                    <div class="testimonial-info">
                                        <div class="thumb">
                                            <img src="assets/img/images/testimonial_avatar03.png" alt="" />
                                        </div>
                                        <div class="content">
                                            <h4 class="title">Davis Levin</h4>
                                            <p>CEO Kawasaki Inc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="testimonial-item">
                                    <div class="testimonial-content">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “</p>
                                    </div>
                                    <div class="testimonial-info">
                                        <div class="thumb">
                                            <img src="assets/img/images/testimonial_avatar04.png" alt="" />
                                        </div>
                                        <div class="content">
                                            <h4 class="title">Davis Levin</h4>
                                            <p>CEO Kawasaki Inc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="testimonial-item">
                                    <div class="testimonial-content">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “</p>
                                    </div>
                                    <div class="testimonial-info">
                                        <div class="thumb">
                                            <img src="assets/img/images/testimonial_avatar02.png" alt="" />
                                        </div>
                                        <div class="content">
                                            <h4 class="title">Davis Levin</h4>
                                            <p>CEO Kawasaki Inc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="testimonial-item">
                                    <div class="testimonial-content">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and “</p>
                                    </div>
                                    <div class="testimonial-info">
                                        <div class="thumb">
                                            <img src="assets/img/images/testimonial_avatar03.png" alt="" />
                                        </div>
                                        <div class="content">
                                            <h4 class="title">Davis Levin</h4>
                                            <p>CEO Kawasaki Inc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           */}
            <section class="consultation-area pt-120 pb-120">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="consultation-content">
                                <div class="section-title mb-25">
                                    <img src="assets/img/icon/consultation_icon01.png" alt=""  />
                                    <span class="sub-title">95% Accuracy</span>
                                    <h2 class="title">Worldwide Best Digital Marketing Agency</h2>
                                </div>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and</p>
                                <div class="consultation-list">
                                    {/* <ul class="list-wrap">
                                        <li>
                                            <div class="icon">
                                                <img src="assets/img/icon/consultation_icon02.png" alt="" />
                                                <span>12x</span>
                                            </div>
                                            <div class="content">
                                                <h6 class="title">Faster Order Processing</h6>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon">
                                                <img src="assets/img/icon/consultation_icon03.png" alt="" />
                                                <span>95%</span>
                                            </div>
                                            <div class="content">
                                                <h6 class="title">Processing Accuracy</h6>
                                            </div>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="consultation-form-wrap">
                                <h4 class="title">Free Consultation</h4>
                                <form action="#">
                                    <div class="form-grp">
                                        <input type="text" placeholder="Name" />
                                    </div>
                                    <div class="form-grp">
                                        <input type="email" placeholder="Email Address"/>
                                    </div>
                                    <div class="form-grp">
                                        <input type="text" placeholder="Phone Number"/>
                                    </div>
                                    <div class="form-grp">
                                        <select id="shortBy" name="select" class="form-select" aria-label="Default select example">
                                            <option value="">Subject</option>
                                            <option>Subject One</option>
                                            <option>Subject Two</option>
                                            <option>Subject Three</option>/
                                            <option>Subject Four</option>
                                        </select>
                                    </div>
                                    <button class="btn" type="submit">Consultation</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="consultation-shape-wrap">
                    <img src="assets/img/images/consultation_shape01.png" alt="" class="shape-one ribbonRotate"/>
                    <img src="assets/img/images/consultation_shape02.png" alt="" class="shape-two float-bob-x"/>
                </div>
            </section>
            
            {/* <section class="blog-area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center mb-50">
                                <span class="sub-title">My Blog</span>
                                <h2 class="title">News & Updates</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-post-item">
                                <div class="blog-post-thumb">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog_img01.jpg" alt=""/></a>
                                </div>
                                <div class="blog-post-content">
                                    <a href="blog.html" class="tag">Branding</a>
                                    <h2 class="title"><a href="blog-details.html">How To Create JavaScript Vanilla Gantt Chart: Adding</a></h2>
                                    <div class="blog-meta">
                                        <ul class="list-wrap">
                                            <li class="avatar-img">
                                                <a href="blog.html"><img src="assets/img/blog/blog_avatar01.png" alt=""/></a>
                                            </li>
                                            <li>By <a href="blog.html">Ataur</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-post-item">
                                <div class="blog-post-thumb">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog_img02.jpg" alt="" /></a>
                                </div>
                                <div class="blog-post-content">
                                    <a href="blog.html" class="tag">Branding</a>
                                    <h2 class="title"><a href="blog-details.html">How To Create JavaScript Vanilla Gantt Chart: Adding</a></h2>
                                    <div class="blog-meta">
                                        <ul class="list-wrap">
                                            <li class="avatar-img">
                                                <a href="blog.html"><img src="assets/img/blog/blog_avatar02.png" alt=""/></a>
                                            </li>
                                            <li>By <a href="blog.html">Ataur</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="blog-post-item">
                                <div class="blog-post-thumb">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog_img03.jpg" alt=""/></a>
                                </div>
                                <div class="blog-post-content">
                                    <a href="blog.html" class="tag">Branding</a>
                                    <h2 class="title"><a href="blog-details.html">How To Create JavaScript Vanilla Gantt Chart: Adding</a></h2>
                                    <div class="blog-meta">
                                        <ul class="list-wrap">
                                            <li class="avatar-img">
                                                <a href="blog.html"><img src="assets/img/blog/blog_avatar03.png" alt=""/></a>
                                            </li>
                                            <li>By <a href="blog.html">Ataur</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            
         <div>
            <section class="newsletter-area pt-110 pb-120">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center mb-80">
                                <span class="sub-title">Get update</span>
                                <h2 class="title">Get latest updates <br/> and dealsi</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="newsletter-form">
                                <form action="#">
                                    <input type="email" placeholder="Enter your email address"/>
                                    <button type="submit" class="btn">Subscribe <span></span></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="newsletter-shape-wrap">
                    <img src="assets/img/images/newsletter_bg_shape.png" alt="" class="bg-shape" />
                    <img src="assets/img/images/newsletter_shape01.png" alt="" class="shape-one" />
                    <img src="assets/img/images/newsletter_shape02.png" alt="" class="shape-two" />
                    <img src="assets/img/images/newsletter_shape03.png" alt="" class="shape-three" />
                    <img src="assets/img/images/newsletter_shape04.png" alt="" class="shape-four" />
                    <img src="assets/img/images/newsletter_shape05.png" alt="" class="shape-five" />
                    <img src="assets/img/images/newsletter_shape06.png" alt="" class="shape-six" />
                </div>
            </section>
        
            </div>
       
{/*        
        <footer>
            <div class="footer-area">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6">
                                <div class="footer-widget">
                                    <div class="logo">
                                        <a href="index.html"><img src="assets/img/logo/w_logo.png" alt="" /></a>
                                    </div>
                                    <div class="footer-social">
                                        <ul class="list-wrap">
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li class="active"><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="footer-contact">
                                        <span>For Support</span>
                                        <h2 class="title"><a href="tel:0123456789">0123 - 567 - 7650</a></h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="footer-widget">
                                    <h4 class="fw-title">Primary Pages</h4>
                                    <div class="fw-link">
                                        <ul class="list-wrap">
                                            <li><a href="index.html">Home</a></li>
                                            <li><a href="about-us.html">About</a></li>
                                            <li><a href="contact.html">Contact</a></li>
                                            <li><a href="services-details.html">Service</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="footer-widget">
                                    <h4 class="fw-title">Pages</h4>
                                    <div class="fw-link">
                                        <ul class="list-wrap">
                                            <li><a href="about-us.html">About</a></li>
                                            <li><a href="contact.html">Pricing</a></li>
                                            <li><a href="contact.html">Contact</a></li>
                                            <li><a href="contact.html">Request for Demo</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="footer-widget">
                                    <h4 class="fw-title">About Store</h4>
                                    <div class="footer-about">
                                        <ul class="list-wrap">
                                            <li><img src="assets/img/icon/phone_icon.svg" alt=""/><a href="tel:0123456789">8 (495) 989—20—11</a></li>
                                            <li><img src="assets/img/icon/mail_icon.svg" alt=""/><a href="mailto:company@gmail.com">company@gmail.com</a></li>
                                            <li><img src="assets/img/icon/loction_icon.svg" alt=""/><span>Ranelagh Place, Liverpool, L3 5UL, England</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="cart-img">
                                    <img src="assets/img/images/cart_img.png" alt=""/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="copyright-text text-end">
                                    <p>© 2023 xilio. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer> */}
        <Footer />
        
        {/* <script src="assets/js/vendor/jquery-3.6.0.min.js"></script>
        <script src="assets/js/bootstrap.min.js"></script>
        <script src="assets/js/isotope.pkgd.min.js"></script>
        <script src="assets/js/imagesloaded.pkgd.min.js"></script>
        <script src="assets/js/jquery.magnific-popup.min.js"></script>
        <script src="assets/js/jquery.odometer.min.js"></script>
        <script src="assets/js/swiper-bundle.min.js"></script>
        <script src="assets/js/jquery.appear.js"></script>
        <script src="assets/js/slick.min.js"></script>
        <script src="assets/js/ajax-form.js"></script>
        <script src="assets/js/parallax.min.js"></script>
        <script src="assets/js/jquery.parallaxScroll.min.js"></script>
        <script src="assets/js/tween-max.js"></script>
        <script src="assets/js/wow.min.js"></script>
        <script src="assets/js/main.js"></script> */}
        
    </>
    
  )
}

export default Home;